<template>
  <BSidebar
    type="is-light"
    :fullheight="true"
    :fullwidth="false"
    :right="true"
    :open="showAdvanceFilter"
    :can-cancel="false"
    overlay
    mobile="fullwidth"
    position="fixed"
    @close="toggleAdvanceFilter"
  >
    <section class="custom-section">
      <div class="columns is-mobile is-marginless">
        <div class="column has-text-left">
          <div class="title-wrapper left black">
            <img :src="homeTeam.logo" alt="Local Team Logo" />
            <span class="is-size-2-tablet is-size-3-mobile">{{ homeTeam.acronym }}</span>
          </div>
        </div>
        <div class="column has-text-right">
          <div class="title-wrapper right black">
            <span class="is-size-2-tablet is-size-3-mobile">{{ visitingTeam.acronym }}</span>
            <img :src="visitingTeam.logo" alt="Visit Team Logo" />
          </div>
        </div>
      </div>
      <div class="columns is-mobile is-marginless">
        <div class="column team">
          <!-- Aqui va el local -->
          <AdvancedFilterOptions v-model="filter.local" />
        </div>
        <div class="column team">
          <!-- Aqui va el visitante -->
          <AdvancedFilterOptions v-model="filter.visit" />
        </div>
      </div>
      <div class="columns is-marginless">
        <div class="column">
          <BButton type="is-info" expanded @click="onClickApplyFilter" :disabled="isAppling">
            Aplicar Filtros
          </BButton>
        </div>
      </div>
      <div class="columns is-marginless">
        <div class="column">
          <BButton type="is-danger" expanded @click="onCloseClicked">Cerrar</BButton>
        </div>
      </div>
    </section>
  </BSidebar>
</template>

<script>
import AdvancedFilterOptions from '@/components/Elements/NextMatches/AdvancedFilterOptions';
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import { clickBtnAplicarFiltros } from '@/utils/analytics';
import { BSidebar } from 'buefy/dist/esm/sidebar';
import { BButton } from 'buefy/dist/esm/button';
let filterAux = null;
export default {
  name: 'AdvanceFilter',
  components: {
    AdvancedFilterOptions,
    BButton,
    BSidebar,
  },
  data() {
    return {
      filter: {
        local: {
          playingAs: [1, 2],
          statistics: {},
          matchResult: [1, 2, 3],
          firstHalf: [1, 2, 3],
          secondHalf: [4, 5, 6],
        },
        visit: {
          playingAs: [1, 2],
          statistics: {},
          matchResult: [1, 2, 3],
          firstHalf: [1, 2, 3],
          secondHalf: [4, 5, 6],
        },
      },
      isAppling: false,
    };
  },
  watch: {
    isLoggedIn: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          if (this.showPostMatchData) {
            this.filter.local.playingAs.splice(1, 1);
            this.filter.visit.playingAs.splice(0, 1);
            if (this.getHomeResultGame > this.getAwayResultGame) {
              this.filter.local.matchResult.splice(2, 1);
              this.filter.local.matchResult.splice(1, 1);
              this.filter.visit.matchResult.splice(0, 1);
              this.filter.visit.matchResult.splice(1, 1);
            } else {
              if (this.getHomeResultGame < this.getAwayResultGame) {
                this.filter.local.matchResult.splice(2, 1);
                this.filter.local.matchResult.splice(0, 1);
                this.filter.visit.matchResult.splice(2, 1);
                this.filter.visit.matchResult.splice(1, 1);
              } else {
                this.filter.local.matchResult.splice(1, 1);
                this.filter.local.matchResult.splice(0, 1);
                this.filter.visit.matchResult.splice(1, 1);
                this.filter.visit.matchResult.splice(0, 1);
              }
            }
          }
        }
      },
    },
    statisticsTypes: {
      immediate: true,
      handler(newValue) {
        this.filter.local.statistics = newValue[0];
        this.filter.visit.statistics = newValue[0];
        this.updateFilters({ filters: this.filter, needsFormat: true });
        this.updateRankingInSubcategories();
      },
    },
    showAdvanceFilter(newValue) {
      if (newValue) {
        filterAux = {
          local: this.cloneFilterOptions(this.filter.local),
          visit: this.cloneFilterOptions(this.filter.visit),
        };
      }
      this.isAppling = false;
    },
    'filter.local.statistics'(newValue, oldValue) {
      if (newValue && (!oldValue || newValue.block !== oldValue.block)) {
        this.filter.visit.statistics = newValue;
      }
    },
    'filter.visit.statistics'(newValue, oldValue) {
      if (newValue && (!oldValue || newValue.block !== oldValue.block)) {
        this.filter.local.statistics = newValue;
      }
    },
    showPostMatchData() {
      if (this.getPremiumAccount) {
        if (this.showPostMatchData) {
          this.filter.local.playingAs.splice(1, 1);
          this.filter.visit.playingAs.splice(0, 1);
          if (this.getHomeResultGame > this.getAwayResultGame) {
            this.filter.local.matchResult.splice(2, 1);
            this.filter.local.matchResult.splice(1, 1);
            this.filter.visit.matchResult.splice(0, 1);
            this.filter.visit.matchResult.splice(1, 1);
          } else {
            if (this.getHomeResultGame < this.getAwayResultGame) {
              this.filter.local.matchResult.splice(2, 1);
              this.filter.local.matchResult.splice(0, 1);
              this.filter.visit.matchResult.splice(2, 1);
              this.filter.visit.matchResult.splice(1, 1);
            } else {
              this.filter.local.matchResult.splice(1, 1);
              this.filter.local.matchResult.splice(0, 1);
              this.filter.visit.matchResult.splice(1, 1);
              this.filter.visit.matchResult.splice(0, 1);
            }
          }
        }
      }
    },
  },
  computed: {
    ...mapGetters('nextGeneral', [
      'showAdvanceFilter',
      'homeTeam',
      'visitingTeam',
      'statisticsTypes',
      'gameId',
      'showPostMatchData',
      'getHomeResultGame',
      'getAwayResultGame',
    ]),
    ...mapGetters('loginGeneral', ['getPremiumAccount', 'isLoggedIn', 'getIsGolstats']),
    ...mapGetters('general', ['getPreviewSectionAnalytics']),
    ...mapState('nextGeneral', ['selectedAnalysis']),
    isMobile() {
      return this.$mq === 'mobile';
    },
  },
  mounted() {
    this.filters = this.storeFilters;
  },
  methods: {
    ...mapMutations('nextGeneral', ['toggleAdvanceFilter', 'updateFilters']),
    ...mapMutations('general', ['setSectionAnalytics']),
    ...mapActions('nextGeneral', ['updateRankingInSubcategories']),
    ...mapActions('alertGeneral', ['setAlert']),
    ...mapMutations(['SET_PREMIUM_MESSAGE']),
    ...mapMutations('auth', ['SET_AUTH_COMPONENT']),
    storeFilters() {
      return this.$store.state.filters;
    },
    onClickApplyFilter() {
      clickBtnAplicarFiltros(this.getPremiumAccount, this.gameId, this.getPreviewSectionAnalytics, this.getIsGolstats);
      if (this.getPremiumAccount) {
        this.isAppling = true;
        setTimeout(() => {
          this.updateFilters({ filters: this.filter, needsFormat: true });
          this.updateRankingInSubcategories();
          this.toggleAdvanceFilter();
        }, 1);
      } else {
        this.onCloseClicked();
        this.setSectionAnalytics('Popup_AF_OP');
        this.SET_PREMIUM_MESSAGE(true);
      }
    },
    onCloseClicked() {
      if (filterAux) {
        this.filter = {
          local: this.cloneFilterOptions(filterAux.local),
          visit: this.cloneFilterOptions(filterAux.visit),
        };
      }
      this.toggleAdvanceFilter();
    },
    cloneFilterOptions(filterOptions) {
      return {
        playingAs: [...filterOptions.playingAs],
        statistics: filterOptions.statistics,
        matchResult: [...filterOptions.matchResult],
        firstHalf: [...filterOptions.firstHalf],
        secondHalf: [...filterOptions.secondHalf],
      };
    },
  },
};
</script>

<style>
.afo-filter-title {
  font-family: 'Avenir-Medium';
  font-size: 0.688rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.002rem;
  color: #2f2f2f;
  text-align: center;
  margin-bottom: 0.3rem;
}
.custom-section {
  padding: 3rem 1rem;
}
.b-sidebar .sidebar-content {
  width: 600px !important;
}
@media screen and (max-width: 400px) {
  .custom-section {
    padding: 1.5rem 0 1.5rem 0;
    width: 100%;
  }
  .team {
    margin-left: -13px;
    margin-right: -13px;
  }
}
</style>
