<template>
  <div>
    <!-- Aqui va el Local -->
    <div class="container has-text-center">
      <!-- Playin as -->
      <div class="afo-filter-title">Jugando como:</div>
      <BField :key="playingAsKey" position="is-centered">
        <BCheckboxButton
          v-model="value.playingAs"
          :size="isMobile ? 'is-small' : ''"
          :disabled="showPostMatchData ? true : false"
          :native-value="1"
        >
          <span class="is-uppercase">Loc</span>
        </BCheckboxButton>
        <BCheckboxButton
          v-model="value.playingAs"
          :size="isMobile ? 'is-small' : ''"
          :disabled="showPostMatchData ? true : false"
          :native-value="2"
        >
          <span class="is-uppercase">Vis</span>
        </BCheckboxButton>
      </BField>
      <!-- Tipo Recibido/Realizado -->
      <BField position="is-centered" type="is-info" class="select-statistics">
        <BSelect v-model="value.statistics" :size="isMobile ? 'is-small' : ''" placeholder="Seleccione" expanded>
          <option v-for="(type, index) in statisticsTypes" :key="index" :value="type">
            {{ type.text }}
          </option>
        </BSelect>
      </BField>
      <!-- Resultados -->
      <div class="afo-filter-title">Resultados</div>
      <BField :key="gameResultKey" position="is-centered">
        <BCheckboxButton
          v-model="value.matchResult"
          :size="isMobile ? 'is-small' : ''"
          :disabled="showPostMatchData ? true : false"
          :native-value="1"
          >G</BCheckboxButton
        >
        <BCheckboxButton
          v-model="value.matchResult"
          :size="isMobile ? 'is-small' : ''"
          :disabled="showPostMatchData ? true : false"
          :native-value="3"
          >E</BCheckboxButton
        >
        <BCheckboxButton
          v-model="value.matchResult"
          :size="isMobile ? 'is-small' : ''"
          :disabled="showPostMatchData ? true : false"
          :native-value="2"
          >P</BCheckboxButton
        >
      </BField>
      <!-- 1er Tiempo -->
      <div class="afo-filter-title">1er Tiempo</div>
      <BField :key="firstHalfKey" position="is-centered">
        <BCheckboxButton v-model="value.firstHalf" :size="isMobile ? 'is-small' : ''" :native-value="1"
          >0-15</BCheckboxButton
        >
        <BCheckboxButton v-model="value.firstHalf" :size="isMobile ? 'is-small' : ''" :native-value="2"
          >16-30</BCheckboxButton
        >
        <BCheckboxButton v-model="value.firstHalf" :size="isMobile ? 'is-small' : ''" :native-value="3"
          >31-45</BCheckboxButton
        >
      </BField>
      <!-- 2do Tiempo -->
      <div class="afo-filter-title">2do Tiempo</div>
      <BField :key="secondHalfKey" position="is-centered">
        <BCheckboxButton v-model="value.secondHalf" :size="isMobile ? 'is-small' : ''" :native-value="4"
          >45-60</BCheckboxButton
        >
        <BCheckboxButton v-model="value.secondHalf" :size="isMobile ? 'is-small' : ''" :native-value="5"
          >61-75</BCheckboxButton
        >
        <BCheckboxButton v-model="value.secondHalf" :size="isMobile ? 'is-small' : ''" :native-value="6"
          >76-90</BCheckboxButton
        >
      </BField>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { BField } from 'buefy/dist/esm/field';
import { BCheckboxButton } from 'buefy/dist/esm/checkbox';
import { BSelect } from 'buefy/dist/esm/select';

export default {
  name: 'AdvancedFilterOptions',
  components: {
    BField,
    BCheckboxButton,
    BSelect,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      playingAsKey: 0,
      gameResultKey: 2000,
      firstHalfKey: 3000,
      secondHalfKey: 4000,
    };
  },
  watch: {
    'value.playingAs'(newValue, oldValue) {
      if (newValue.length === 0) {
        this.value.playingAs = oldValue;
        this.playingAsKey++;
      }
    },
    'value.matchResult'(newValue, oldValue) {
      if (newValue.length === 0) {
        this.value.matchResult = oldValue;
        this.gameResultKey++;
      }
    },
    'value.firstHalf'(newValue, oldValue) {
      if (newValue.length === 0 && this.value.secondHalf.length === 0) {
        this.value.firstHalf = oldValue;
        this.firstHalfKey++;
      }
    },
    'value.secondHalf'(newValue, oldValue) {
      if (newValue.length === 0 && this.value.firstHalf.length === 0) {
        this.value.secondHalf = oldValue;
        this.secondHalfKey++;
      }
    },
  },
  computed: {
    ...mapGetters('nextGeneral', ['statisticsTypes', 'showPostMatchData']),
    isMobile() {
      return this.$mq === 'mobile';
    },
  },
};
</script>
<style>
.b-checkbox.checkbox.button.is-primary[disabled] {
  color: white !important;
}
.b-checkbox.checkbox.button.is-primary {
  background: #4099ff !important;
}
.button.is-focused {
  border-color: #4099ff !important;
}
</style>
<style scoped>
.afo-filter-title {
  font-family: 'Avenir-Medium';
  font-size: 0.688rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.002rem;
  color: #2f2f2f;
  text-align: center;
  margin-bottom: 0.3rem;
}

@media screen and (max-width: 400px) {
  .afo-filter-title {
    font-size: 0.55rem !important;
  }
  .select-statistics {
    width: 96%;
    margin-left: 2%;
    margin-right: 2%;
  }
}
</style>
